<template>
  <base-page>
    <el-row class="main-page">
      <el-col class="coloana" :span="8">
        <el-card style='margin:5px 0px 5px 0px'>
          <div slot="header">
              <span>Interfata</span>
              <!-- <el-button @click="save" style="float: right; padding: 6px 12px; " type="success" plain round size="large"> Salveaza </el-button> -->
          </div>
          <el-row :gutter="15">

            <el-col :md="6" size="small"><img :src="base_url.replace('/api/index.php', '')+ '/api/assets/logo/logo.jpeg'" :key="cheie"  alt="logo" style="max-height: 100px" /> 
              <el-upload class="upload-demo" 
              :action="base_url + '/setari/upload_logo'" 
              list-type="picture"
              accept="picture"
              :auto-upload="true" 
              :limit="1" 
              :file-list="TempFile"
              :show-file-list="true"
              :on-change="handleAvatarSuccess"
              >
                <br>
                <el-button size="small" type="primary">Schimba logo</el-button>
              </el-upload>
            </el-col>
            
          </el-row>
          <el-row :gutter="15">
            <el-col :md="24">
              <hr/>
              <div v-if="TokenValabilPanaLa != ''">
                Token valabil pana la: {{ TokenValabilPanaLa }}
              </div>
              <div v-else>
                Token INACTIV
              </div>
              <a target="_blank" class="el-button el-button--primary el-button--small" style="text-decoration: none;" :href="base_url + '/ApiEfactura/auth'">Refacere token e-factura</a>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from "@/widgets/TitluPagina";

export default {
  name: "setari",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      selectedObject: {
        // Iconita: "",
      },
      TempFile: [],
      Results: [],
      base_url: "",
      TokenValabilPanaLa:"",
      Info: {},
      cheie: 0,
      rules: {
        // Iconita: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    // save: async function() {
    //   this.$refs['my-form'].validate( async(valid) => {
    //       if (valid)
    //       {
    //         var save_response = await this.post("setari/save", {Setari: this.selectedObject } );
    //         if(save_response.Raspuns){
    //             this.$notify({
    //                 title: 'Succes',
    //                 message: 'Modificarile au fost salvate!',
    //                 type: 'success',
    //             });
    //         } else {
    //             this.$notify({
    //                 title: 'Eroare',
    //                 message: 'A aparut o problema - modificarile nu au fost salvate!',
    //                 type: 'error',
    //             });
    //         }
    //       }
    //   });
    // },
    handleAvatarSuccess(res, file) {
        setTimeout(() => {
          location.reload();
      }, 2000);
    },
      
    async refresh_info() {
      var response = await this.post("setari/index");
      this.selectedObject = response.Setari;
      this.select_menu_item("setari");
      this.TokenValabilPanaLa = response.TokenValabilPanaLa;
    },
    
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.refresh_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }
  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }
  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}


.main-page {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  .coloana {
      width: 95%;
    }
}

</style>
